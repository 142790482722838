//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import Select from 'element-ui'
import toulouseQuartiers from '../assets/toulouse.json'

Vue.use(Select)

const API_URL = 'https://geo.api.gouv.fr/communes?nom=:query&fields=nom,code,centre&boost=population&limit=5'
// const ApiUrlCommune = 'https://geo.api.gouv.fr/communes?nom=:query&fields=centre,codesPostaux&boost=population&limit=1'

export default {
  data () {
    return {
      options: [],
      value: [],
      list: [],
      query: '',
      content: '',
      listQuartiers: toulouseQuartiers,
      loading: false,
      loadingText: 'Chargement',
      emptyText: 'Aucune ville ou quartier correspondant'
    }
  },
  methods: {
    remoteMethod (query) {
      if (query !== '') {
        this.query = query
        this.loading = true
        const arraySelections = []
        if (this.value.length > 0) {
          this.value.forEach(function (item) {
            arraySelections.push({ value: item.value, label: item.label })
          })
        }
        const arrayQuartiers = []
        this.listQuartiers.filter(item => {
          if (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            arrayQuartiers.push({ value: item.value, label: item.label })
          }
        })
        const arrayVilles = []
        fetch(API_URL.replace(':query', query))
          .then((response) => {
            response.json()
              .then((result) => {
                const geoCodeAdresses = result
                Object.entries(geoCodeAdresses).forEach(([index, value]) => {
                  arrayVilles.push({ value: value.centre.coordinates[1] + '@' + value.centre.coordinates[0], label: value.nom + ' (' + value.code + ')' })
                })
                const list = [
                  {
                    label: 'Sélection',
                    options: arraySelections
                  },
                  {
                    label: 'Ville',
                    options: arrayVilles
                  }
                ]
                if (arrayQuartiers.length > 0) {
                  list[2] = {
                    label: 'Quartier',
                    options: arrayQuartiers
                  }
                }
                this.options = list
                this.loading = false
              })
          })
      } else {
        this.options = []
      }
    },
    select (values) {
      this.remoteMethod(this.query)
      let toSend = ''
      let cityNames = ''
      for (let city of values) {
        if (toSend === '') {
          toSend = city.value
        } else {
          toSend = toSend + ';' + city.value
        }
        if (cityNames === '') {
          cityNames = city.label
        } else {
          cityNames = cityNames + ';' + city.label
        }
      }
      this.$emit('changedGPSData', toSend, cityNames)
    }
  }
}
